<template>
    <div id="eventKategorie" :class="[ isEventPage ? 'content-resize' : 'content' ]" class="mx-1 gap-3">
        <div>
          <div v-if="!event">Event: {{event}}</div>
          <div class="other-sub row justify-content-center">
            Event 2024 <!-- TODO: remove year as this should be part of the title -->
          </div>
          <div class="title" v-html="getFormattedEventName(event.name)"/>
        </div>
        <div :class="[ isEventPage ? 'description-resize' : 'description' ]" v-html="event.description"/>
        <div :class="[ isEventPage ? 'calendar-location-resize' : 'calendar-location' ]">
            <div class="calendar-container gap-3">
                <img class="calendar" src="@/images/calendar-icon_darkblue.svg" alt="calendar">
                <p>{{ dateFormat(event.date) }}</p> <!-- always converted from UTC to time zone at the office in Linz! -->
            </div>
            <div class="location-container gap-3">
                <img class="location" src="@/images/place_icon-darkblue.svg" alt="location">
                <p>{{ event && event.location ? (`${event.location.designation} \n${getFormattedAddress(event.location.address)}`) : '' }}</p>
            </div>
        </div>
        <div class="button-area">
            <button @click="getEvent">
                Zur Anmeldung
            </button>
        </div>
    </div>
</template>

<script>
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone"

export default {
    props: ['selectedEvent', 'isEventPage'],

    data() {
        return {}
    },
    computed: {
        event() {
            let event = this.selectedEvent.uid ? this.selectedEvent : this.$store.state.event;
            console.log(event);
            return event;
        }
    },
    methods: {
        getEvent() {
            this.$emit('registrationEvent', this.event);
            const el = document.getElementById('registration');
            console.log(el);
            if(el) {
              el.scrollIntoView({behavior: 'smooth'});
            }
        },
        dateFormat(timestampUnixMs) {
          const zoneLinz = 'Europe/Berlin';
          // const isoFormat = 'YYYY-MM-DDTHH:mm:ss.sssZ';
          // const startDateUnix = new Date(timestampUnixMs);
          // Note: UTC <> GMT; UTC 0 = 1.1.1970
          // let dateLinz = dayjs(startDateUnix.toISOString(), isoFormat, zoneLinz); // ISO String format: YYYY-MM-DDTHH:mm:ss.sssZ -> toISOString always UTC (as 'Z' appended)

          dayjs.extend(utc);
          dayjs.extend(timezone);

          const startDateUnix = dayjs(timestampUnixMs);
          const startDateLinz = startDateUnix.tz(zoneLinz);
          // date is in UTC, tz adapts it to the timezone of the s2g office (Linz),
          return ` ${startDateLinz.format('DD. MMMM YYYY')} \n Start: ${startDateLinz.format('HH:mm')} \n Einlass: ${startDateLinz.add(-45, 'minute').format('HH:mm')}`;
        },
        getFormattedEventName(eventName) {
            let formatted = eventName ? eventName.replace(';', "\n<span class='first-sub'>") : '';
            formatted = formatted.replaceAll(';', "\n</span><span class='other-sub'>")
            if(formatted.includes("\n")) {
              formatted = formatted.concat("</span>");
            }
            return formatted;
        },
        getFormattedDescription(description) {
          return description.trim();
        },
        getFormattedAddress(address) {
          return address.replace(', ', '\n');
        }
    }
}
</script>


<style lang="scss" scoped>
    #eventKategorie {
      //@media (prefers-color-scheme: dark) {
      //  color: white;
      //}
      //
      //@media (prefers-color-scheme: light) {
      //  color: black;
      //}
    }

    .title {
      display: inline-flex;
      justify-content: center;
      flex-direction: column;
    }

    .content, .content-resize {
        box-sizing: content-box;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .description, .description-resize{
        width: 90%;
        //word-break: break-word;
        letter-spacing: -0.8px;
        white-space: pre-line;
        text-align: justify; // Blocksatz
    }

    .calendar-location, .calendar-location-resize {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .calendar-location p {
        width: 100%;
        //align-self: center;
    }

    .location-container, .calendar-container {
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: start;
        align-items: center;

        p {
          white-space: pre-wrap;
          text-align: center;
        }

        img {
          width: 24px;
        }
    }

    .button-area {
        align-self: center;

      button {
        background-color: var(--s2g-event-color);
      }
    }

    //.button-off{
    //    display: none;
    //}

    a {
        color: white;
        text-decoration: none;
    }

</style>
