import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import config from '@/config/config.json';
import dayjs from 'dayjs';

Vue.use(Vuex);

const initialState = () => {
    return {
        events: {},
        event: {},
        titles: [],
        images: []
    };
}

export default new Vuex.Store({
    state: initialState(),
    mutations: {
        fetchEvents(state, {events}) {
            state.events = events;
        }
    },
    actions: {
        UP_COMING_EVENT: (context , payload) => {
            context.state.event = payload.events[0]
        },
        FETCH_EVENTS: async (context) => {
            await axios.get(config.apiUrl + "/event/getAllEvents", {
                headers: {
                  "Content-Type": "application/json"
                }
              }).then(response => {
                const events = context.getters.FILTER_EVENTS(response.data);
                context.dispatch('FETCH_IMAGES_TITLES_FOR_EVENTS', { events: events });
            })
        },
        UPDATE_EVENT: (_, payload) => {
            console.log(payload)
            axios.put(config.apiUrl+'/event/saveEvent', payload, {
                headers:{
                    'Content-type' : 'application/json'
                },
            }).then(result => { 
                if(result.data) {
                    console.log(result.data)
                    Vue.notify({
                        group: 'all',
                        type: 'success',
                        text:`Event: ${result.data.name} wurde erfolgreich updated`
                      });
                }
            });
        },
        UPLOAD_IMAGE: (_, payload) => {
            let body = {uid: '3692d972-eff1-422c-a986-cd5ba210ccf8', image: payload.image} // TODO: why fix uid?
            axios.post(config.apiUrl+'/event/save/image', body, {
                headers:{
                    'Content-type' : 'application/json'
                },
            }).then(result => { 
                console.log(result);
            });
        },
        UPLOAD_IMAGE_FILE: (_, payload) => {
            let body = {uid: '3692d972-eff1-422c-a986-cd5ba210ccf8', image: payload} // TODO: why fix uid?
            axios.post(config.apiUrl+'/event/image/upload', body, {
                headers:{
                    'Content-type' : 'application/json'
                },
            }).then(result => { 
                console.log(result);
            });
        },
        UPLOAD_FILE_EVENT: (_, payload) => {
            console.log(payload)
            axios.post(config.apiUrl+'/event/file/event/upload', payload.files, {
                headers:{
                    'Content-type' : 'multipart/form-data'
                },
                params: {
                    eventUid : payload.event.uid
                }
            }).then(result => { 
                if(result.data) {
                    console.log(result)
                    Vue.notify({
                        group: 'all',
                        type: 'success',
                        text:`Image ${result.data.image} zur Event: ${result.data.name} wurde erfolgreich gespeichert`
                      });
                }
            });
        },
        UPLOAD_FILE: (_, payload) => {
            return axios.post(config.apiUrl+'/event/file/upload', payload.files, {
                headers:{
                    'Content-type' : 'multipart/form-data'
                },
            }).then(result => { 
                console.log(result);
                return result;
            });
        },
        DOWNLOAD_FILE: (_, payload) => {
            return axios.get(config.apiUrl+'/event/file/download', {
                headers:{
                    'Content-type' : 'application/json'
                },
                params: {
                    eventUid : payload.event.uid
                }
            }).then(result => {
                return result.data;
            });
        },

        /* Fetch events with image in base64 which can be used directly in bind src */
        /* Question: images and titles Array hier needed or better forEach in EventsPage form events ? */
        FETCH_IMAGES_TITLES_FOR_EVENTS: async (context, payload) => {
            let events = [];
            let images = [];
            let titles = [];
            for(let i=0; i<payload.events.length; i++) {
                await context.dispatch('DOWNLOAD_FILE', { event : payload.events[i]})
                .then(result => {
                    if(result) {
                        let title = result.name;
                        let image = `data:image/png;base64,${result.image}`
                        images.push(image)
                        titles.push(title);
                        let event = {...result};
                        event.image = `data:image/png;base64,${result.image}`;
                        events.push(event);
                    }
                });                
            }
            context.state.images = images;
            context.state.titles = titles;
            context.commit('fetchEvents', {events : events});
            context.dispatch('UP_COMING_EVENT', { events: events });
        },
    },
    getters: {
        /* Ordering upcoming events pro date */
        FILTER_EVENTS: () => payload => {
            const nextEvents = [];
            let today = dayjs().startOf('day').unix();
            payload.filter( event => {
                const date = dayjs(event.date).unix();
                if(date >= today) {
                    nextEvents.push(event)
                }
            });
            return nextEvents.filter(s => new Date(s.date) >= today).sort((a,b) => new Date(a.date) - new Date(b.date));
        },
        /* decode Image from Base64*/
        decodeImage: () => payload => {
            let image = payload;
            if(image === undefined || image === null || image === '') {
                return require('../images/bird_chatbox.png')
            }
            
            return Buffer.from(image, 'base64').toString('utf8');
        },

    },
    modules: {}
})