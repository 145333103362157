<template>
    <div v-show="visibleSlide === index" class="carrousel-slide">
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: ['visibleSlide', 'index'],
}
</script>

