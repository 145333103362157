<template>
  <div class="container-fluid">
    <!-- Note: with floating labels the placeholder TEXT is ignored - label content is used instead,
    BUT labels don't float (change position), if no placeholder is set -->

    <form @submit.prevent class="needs-validation" novalidate>
     <fieldset :disabled="!selectedEvent || !selectedEvent?.registrationPossible">
      <div class="container-form container col col-md-6 justify-content-center px-5 py-1">
        <img v-if="!selectedEvent?.registrationPossible" class="ausgebucht" src="../images/ausgebucht.svg" />
        <div class="row head justify-content-center align-items-center">
          <div class="title">ANMELDUNG</div>
          <div class="subtitle" v-html="selectedEvent?.name ? getFormattedEventName(selectedEvent?.name) : ''"/>
          <!--                <div class="container-dropdown">-->
          <!--
          <select v-model="selectedEvent">
            <option v-for="(event, index) in events"
              :value="event"
              :key="index">
              {{ event.name }} : {{ dateFormat(event.date) }}
            </option>
          </select>
          -->
          <!--                </div>-->
        </div>
        <div class="row row-cols-1 row-cols-sm-2 g-2">
          <div class="col d-inline-flex form-floating">
            <input class="col required flex-fill form-control" type="text" id="firstname" name="firstname" placeholder="Vorname"
                   required v-model="firstName">
            <label for="firstname">* Vorname</label>
          </div>
          <div class="col d-inline-flex form-floating">
            <input class="col required flex-fill form-control" type="text" id="lastname" name="lastname" placeholder="Nachname"
                   required v-model="lastName">
            <label for="firstname">* Nachname</label>
          </div>
        </div>
        <div class="row row-cols-1 row-cols-sm-2 g-2">
          <!-- "Note: The displayed date format will differ from the actual value — the displayed date is formatted based on the locale of the user's browser, but the parsed value is always formatted yyyy-mm-dd." (from https://developer.mozilla.org/) -->
          <div class="col birthday d-inline-flex flex-column form-floating">
            <input class="required form-control" type="date" id="birthday" name="birthday" v-model="birthday" :min="parsedMinDate" :max="parsedMaxDate" placeholder="Geburtsdatum">
            <label for="birthday">* Geburtsdatum</label>
          </div>
        </div>
        <div class="row row-cols-1 row-cols-sm-2 g-2">
          <div class="col-12 d-inline-flex form-floating">
            <input class="flex-fill form-control" type="text" id="company" name="company" placeholder="Firma"
                   v-model="company">
            <label for="company">Firma</label>
          </div>
          <div class="col d-inline-flex form-floating">
            <input class="flex-fill form-control" type="text" id="department" name="department" placeholder="Abteilung"
                   v-model="department">
            <label for="department">Abteilung</label>
          </div>

        </div>
        <div class="row row-cols-1 row-cols-sm-2 g-2">
          <div class="col d-inline-flex form-floating">
            <input class="flex-fill form-control" type="text" id="companyAddress" name="companyAddress" v-model="companyAddress" required placeholder="Firmenadresse">
            <label for="companyAddress">Firmenadresse</label>
          </div>
        </div>
        <div class="row row-cols-1 row-cols-sm-2 g-2">
          <!-- Note: with floating labels the placeholder text is ignored, but labels don't float, if no placeholder is set -->
          <div class="col d-inline-flex form-floating">
            <input class="flex-fill form-control" type="text" id="email" name="email" v-model="email" required placeholder="Email">
            <label for="email">* E-Mail</label>
          </div>
        </div>
        <div class="row g-2">
          <div class="col d-inline-flex form-floating">
                <textarea id="comment" class="flex-fill form-control" name="comment"
                          placeholder="Kommentar, Lebensmittel-Unverträglichkeiten, ..."
                          v-model="comment"/>
            <label for="comment">Kommentar, Lebensmittel-Unverträglichkeiten, ...</label>
          </div>
        </div>

        <div>Felder mit * sind Pflichtfelder</div>
        <div class="pt-1">
          <label class="new-events-checkbox">
            <input type="checkbox" class="new-events-checkbox" v-model="newEvents">
            Ja, ich möchte über neue Events und Neuigkeiten informiert werden.
          </label>
        </div>
        <div>
          <label class="datenschutz-checkbox">
            <input type="checkbox" v-model="dataProtection" required>
            * Ich willige der
            <a href="https://www.s2g.at/dataprotection" target="_blank">Datenschutzerklärung</a>
            ein
          </label>
        </div>
        <div class="row">
          <div class="d-inline-flex justify-content-center">
            <button class="button" @click="checkForm" type="submit" :disabled="!selectedEvent || !selectedEvent?.registrationPossible">ANMELDEN</button>
          </div>
        </div>
        <div class="event-email-text">
          <p>Für weitere Informationen bitte E-Mail an <a href="mailto:event@s2g.at" target="_blank">event@s2g.at</a>
          </p>
        </div>

      </div>
     </fieldset>
    </form>
  </div>
</template>

<script>
import config from '@/config/config.json';
import dayjs from "dayjs";
import Vue from 'vue';
import axios, {HttpStatusCode} from 'axios';

export default {
  props: ['eventForRegistration'],
  data() {
    return {
      uid: '',
      firstName: '',
      lastName: '',
      email: '',
      birthday: '',
      company: '',
      department: '',
      companyAddress: '',
      comment: '',
      newEvents: false,
      dataProtection: false,
      creationDate: dayjs().valueOf(),
      registration: true,
      selectedEvent: '',
    }
  },

  watch: {
    events: {
      handler(newValue, oldValue) {
        console.log(newValue);
        console.log(oldValue);
        if (newValue.length > 0) {
          this.selectedEvent = newValue[0];
        }
      },
      deep: true,
      immediate: true
    },
    eventForRegistration: {
      handler(newValue) {
        this.selectedEvent = newValue;
      },
      immediate: true
    }
  },

  computed: {
    events() {
      return this.$store.state.events;
    },
    parsedMaxDate() {
      const minimumAge = 12;
      const date = new Date();
      date.setFullYear(date.getFullYear() - minimumAge);

      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();

      // format needed as: yyyy-mm-dd (yyyy-m-dd does not work!)
      let maxDate = `${year}-${month<10 ? '0' : ''}${month}-${day}`;
      console.log('maxDate: ', maxDate);
      return maxDate;
    },
    parsedMinDate() {
      const maximumAge = 120;
      const date = new Date();
      date.setFullYear(date.getFullYear() - maximumAge);

      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();

      // format needed as: yyyy-mm-dd (yyyy-m-dd does not work!)
      let minDate = `${year}-${month<10 ? '0' : ''}${month}-${day}`;
      console.log('minDate: ', minDate);
      return minDate;
    }
  },

  methods: {
    getFormattedEventNameForConfirmation() { // no html tags included
      // let formatted = eventName ? eventName.replace(';', "\n") : '';
      // formatted = formatted.replaceAll(';', "\n");
      //
      // return formatted;
      //TODO: short event name field or clear rule, if this text shall be dynamically generated
      return "\"Transform IT Talk\" CHANGE MANAGEMENT";
    },
    getFormattedEventName(eventName) {
      let formatted = eventName ? eventName.replace(';', "\n<span class='fw-bold'>") : '';
      formatted = formatted.replaceAll(';', "\n</span><span class='fw-normal'>")
      if(formatted.includes("\n")) {
        formatted = formatted.concat("</span>");
      }
      return formatted;
    },
    isEmailValid(email) {
      const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      console.log(new RegExp(validRegex).test(email));
      return new RegExp(validRegex).test(email);
    },
    isBirthdayValid(birthday) { // birthday is a string value! parsedMinDate and parsedMaxDate also!
      // supported Date-only forms: YYYY, YYYY-MM, YYYY-MM-DD (https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date#date_time_string_format)
      console.log(birthday);
      let parsedDate = new Date(birthday);
      console.log('parsedDate: ', parsedDate, ' min: ', new Date(this.parsedMinDate), ' max: ', new Date(this.parsedMaxDate));
      console.log('parsedDate: ', parsedDate.getTime(), ' min: ', new Date(this.parsedMinDate).getTime(), ' max: ', new Date(this.parsedMaxDate).getTime());
      if(parsedDate < new Date(this.parsedMinDate) || parsedDate > new Date(this.parsedMaxDate)) {
        return false;
      }
      return true;
    },
    checkForm() {
      if (this.firstName.trim().length === 0) {
        Vue.notify({
          group: 'all',
          type: 'error',
          text: 'Vorname darf nicht leer sein!',
          duration: 5000
        });

        return;
      }

      if (this.lastName.trim().length === 0) {
        Vue.notify({
          group: 'all',
          type: 'error',
          text: 'Nachname darf nicht leer sein!',
          duration: 5000
        });

        return;
      }

      if (this.birthday.trim().length === 0) {
        Vue.notify({
          group: 'all',
          type: 'error',
          text: 'Geburtsdatum darf nicht leer sein!',
          duration: 5000
        });

        return;
      }
      if (!this.isBirthdayValid(this.birthday)) {
        Vue.notify({
          group: 'all',
          type: 'error',
          text: `Geburtsdatum ist nicht gültig! Es muss zwischen ${new Date(this.parsedMinDate).toLocaleDateString()} und ${new Date(this.parsedMaxDate).toLocaleDateString()} liegen.`,
          duration: 5000
        });
        return;
      }

      if (this.email.trim().length === 0) {
        Vue.notify({
          group: 'all',
          type: 'error',
          text: 'E-Mail darf nicht leer sein!',
          duration: 5000
        });
        return;
      }

      if (!this.isEmailValid(this.email)) {
        Vue.notify({
          group: 'all',
          type: 'error',
          text: 'E-Mail Adresse ist nicht gültig',
          duration: 5000
        });
        return;
      }

      if (!this.dataProtection) {
        Vue.notify({
          group: 'all',
          type: 'error',
          text: 'Datenschutzerklärung muss bestätigt werden!',
          duration: 5000
        });

        return;
      }

      this.createGuest();
    },

    async createGuest() {

      let newGuest = {
        uid: this.uid,
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        birthday: this.birthday,
        company: this.company,
        division: this.department,
        companyAddress: this.companyAddress,
        messageFutureEvent: this.newEvents,
        lastChange: 0
      }

      await axios.post(config.apiUrl + "/partner", newGuest, {
        headers: {
          "Content-Type": "application/json"
        }
      }).then(async response => {
        console.log(this.selectedEvent.uid);

        if (response.data) {

          await axios.post(config.apiUrl + "/partnerEvent", {comment: this.comment}, {
            headers: {
              "Content-Type": "application/json"
            },
            params: {
              'partnerUid': response.data.uid,
              'eventUid': this.selectedEvent.uid
            }
          }).then((res) => {
            if (res.data) {
              this.submitSuccess();
            }
          });
        }
      }).catch((reason) => {
        // TODO: dynamic shortened event name in error message
        console.log(reason);
        Vue.notify({
          duration: 5000,
          group: 'all',
          type: 'error',
          title: reason?.response?.status === HttpStatusCode.Conflict ?
              "Diese Email-Adresse ist bereits für das Event \"Transform IT Talk\" registriert." : 'Ein Fehler ist aufgetreten - Bitte kontaktieren Sie uns direkt per Email!'
        });
      });
    },

    submitSuccess() {
      this.registration = false;
      Vue.notify({
        duration: 10000,
        group: 'all',
        type: 'success',
        text: `Vielen Dank für die Anmeldung zum Event ${this.getFormattedEventNameForConfirmation()}`
      });
      this.clearFields();
    },

    clearFields() {
      this.firstName = '',
      this.lastName = '',
      this.email = '',
      this.birthday = '',
      this.company = '',
      this.department = '',
      this.companyAddress = '',
      this.comment = '',
      this.newEvents = false,
      this.dataProtection = false
    },

    registrationEvent(event) {
      console.log(event);
    }
  }
}
</script>

<style lang="scss" scoped>

.title {
  display: inline-flex;
  justify-content: center;
  color: var(--s2g-primary-color);
}

.subtitle {
  font-size: var(--font-size-subtitle-other);
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  white-space: pre-line;
  text-align: center;
  line-height: 1.35;
}

p {
  align-self: center;
}

.head {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Style inputs, select elements and textareas */
input[type=text], input[type=date], textarea {
  padding: 7px 7px 7px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: none; // relevant for textarea
  background-color: #dfdfdf!important; // also on focus of textarea -> !important
}

//input[type=date]::-webkit-calendar-picker-indicator {
//  color: red !important;
//}

//.required {
//  border-color: red;
//}

.btn {
}

/* Style the container-form */
.container-form {
  margin-top: 5%;
  border-radius: 5px;
  background: rgba(255, 255, 255, .75);
  border-radius: 10px;
  z-index: 0;
  color: var(--bs-secondary);
  // pointer-events: none; // work with disabled-attribute on form (inputs), based on condition
  max-width: 1000px;

  .row {
    margin-top: 10px;
  }
}

.ausgebucht {
  position: absolute;
  max-width: 500px;
  min-width: 200px;
  margin-top: 50px;
  width: 50%;
  z-index: 2;
}

.overlay {
  display: grid;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgb(107, 100, 100, 0.2);
}

.birthday {
  //input {
  //  color: var(--color-text);
  //}

  //label {
  //  color: var(--bs-secondary);
  //}
}

#comment {
  flex: 1; // or use flex-fill class
  height: 100px;
}

label.new-events-checkbox, label.datenschutz-checkbox {
  margin-left: 12px;
}

.event-email-text {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.container-dropdown {
  display: block;
  margin-top: 0;
  margin-left: 10%;
  margin-bottom: 10%;
}

select {
  width: 80%;
  border-radius: 5px;
}

.form-floating {

  label::after { // applies to the floating label
    background-color: transparent!important;
  }

  label { // no fixed paddings, if floating labels shall be used!
    //padding: 12px 12px 12px 0;
    //display: inline-block;
    text-overflow: ellipsis;
    width: 100%;
    //height: var(--font-size-title);
    overflow: hidden!important;

    //white-space: normal!important;
  }

}

</style>
