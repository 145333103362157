import { render, staticRenderFns } from "./ImpressumPage.vue?vue&type=template&id=6132b63c&scoped=true"
import script from "./ImpressumPage.vue?vue&type=script&lang=js"
export * from "./ImpressumPage.vue?vue&type=script&lang=js"
import style0 from "./ImpressumPage.vue?vue&type=style&index=0&id=6132b63c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6132b63c",
  null
  
)

export default component.exports