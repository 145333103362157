<template>
    <div id="landPage" class="standard">
        <div class="header">
          <div class="logo-container">
            <a href="https://www.s2g.at/" target="_blank">
              <img alt="s2G logo" class="logo" src="../images/s2G_logo.png"/>
            </a>
          </div>
          <div class="links menu">
              <div><a @click="scrollToRegistration()">Anmeldung</a></div>
              <!-- <div><a href="">Archiv</a></div> -->
<!--              <div><a @click="scrollToInfoPage()">Über Uns</a></div>-->
          </div>
        </div>
        <div class="event-kategorie" >
            <div v-if="!event?.uid" class="flex-column justify-content-center">
              <div>Lade Events ...</div>
              <div class="flex-row d-flex justify-content-center">
                <div class="spinner-border s2g-spinner" role="status">
                  <span class="visually-hidden">Lade Events...</span>
                </div>
              </div>
            </div>
            <EventKategorie v-else :selectedEvent="event" @registrationEvent="registrationEvent"/>
        </div>
    </div>
</template>

<script>
import EventKategorie from './EventKategorie.vue';

export default {
    props:[],
    components: { EventKategorie },

    data() {
        return {};
    },
    computed: {
        event() {
          console.log('LandPage: store event: ', this.$store.state.event, ' all events: ', this.$store.state.events);
            return this.$store.state.event;
        }
    },
    methods: {
        scrollToInfoPage() {
          const el = document.getElementById('infoPage');
          console.log(el);
          if(el) {
            el.scrollIntoView({behavior: 'smooth'});
          }
        },
        scrollToRegistration() {
          const el = document.getElementById('registration');
          console.log(el);
          if(el) {
            el.scrollIntoView({behavior: 'smooth'});
          }
        },
        registrationEvent(e) {
            this.$emit('registrationEvent', e)
        }
    }
}

</script>

<style lang="scss" scoped>

    .event-kategorie {
      width: 80%;
      max-width: 1000px;
      margin: auto;
      padding: 5%;
      display: flex;
      justify-content: center;
      background: rgba(255, 255, 255, .85);
      border-radius: 10px;
    }

</style>
