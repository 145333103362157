<template>
    <div>
    <div class="container">
      <div class="container-form">
          <form @submit.prevent >
              <div class="row head">
                <div class="col-100">
                  <select v-model="selectedEvent">
                    <option v-for="(event, index) in events"
                      :value="event"
                      :key="index">
                      {{ event.name }} : {{ dateFormat(event.date) }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="row">
                  <div class="col-100">
                      <input type="text" id="name" name="name" :placeholder="event.name" v-model="event.name">
                  </div>
              </div>
              <div class="row">
                  <div class="col-100">
                      <input type="text" id="description" name="description" :placeholder="event.description" v-model="event.description">
                  </div>
              </div>
              <div class="row">
                  <div class="col-40 birthday">
                      <input type="text" id="location" name="location"  :placeholder="location.address" v-model="location.address">
                  </div>
              </div>
              <div class="row">
                <span class="">
                    <input id="profilePictureUpload" type="file" accept="image/*"  @change="uploadFileForEvent($event)">
                </span>
              </div>
              <div class="btn">
                  <input type="submit" value="Save" @click="updateEvent()">
              </div>
          </form>
      </div>
    </div>
        
    </div>
</template>

<script>

import dayjs from "dayjs";

export default {
    data(){
        return {
            visibleSlide: 1,
            buttonActive: true,
            selectedEvent: '',
            isEventPage: true,
            image: {},
            file: {},
            event: {},
            location: {}
        }
    },

    computed: {
        events() {
          console.log('events on admin page: ', this.$store.state.events);
            return this.$store.state.events;
        }
    },

    watch:{
        selectedEvent: {
            handler(newValue, oldValue){
                if(!newValue.uid || newValue.uid != oldValue.uid){
                    this.initializeEditValues(newValue);
                }
            },
            deep: true
        },

        file: {
            handler(newValue, oldValue){
                if(!newValue.uid || newValue.uid != oldValue.uid){
                    console.log(newValue);
                }
            },
            deep: true
        }
    },
    mounted() {
      this.$store.dispatch('FETCH_EVENTS');
    },

    methods: {
        uploadImage(event){
            let file = event.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(file)
            reader.onload = () => {
                if(confirm('Do you want to upload ' + file.name + '?')){
                    this.$store.dispatch('UPLOAD_IMAGE', { image : reader.result });
                }
            }
        },
        /*used for General File being saved in DB*/
        uploadFile(event){
            let file = event.target.files[0];
            console.log(file)
            const reader = new FileReader();
            reader.readAsDataURL(file)
            reader.onload = () => {
                if(confirm('Do you want to upload ' + file.name + '?')){
                    const formData = new FormData();
                    formData.append('files', file);
                    this.file = this.$store.dispatch('UPLOAD_FILE', { files : formData });
                    console.log(this.file)
                }
            }
        },
        uploadFileForEvent(event){
            let file = event.target.files[0];
            this.file = file;
        },
        updateEvent() {
            let selectedEvent = this.selectedEvent;
            
            let file = this.file;
            console.log(file.name)

            let newEvent = {
                uid: selectedEvent.uid,
                name: this.event.name,
                date: this.event.date,
                description: this.event.description,
                image: selectedEvent.image,
                lastChanged: selectedEvent.lastChange,
                location: this.location,
            }

            this.$store.dispatch('UPDATE_EVENT', newEvent);

            if(this.file.name !== undefined) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    if(confirm('Do you want to upload ' + file.name + '?')){
                        const formData = new FormData();
                        formData.append('files', file);
                        console.log(formData)
                        this.$store.dispatch('UPLOAD_FILE_EVENT', { event: newEvent, files : formData });
                    }
                }
            }

            this.clearField();
        },
        clearField() {
            this.event.name = '';
            this.event.description = '';
            this.location.address = '';
            this.file = '';
        },
        initializeEditValues(selectedEvent) {
          this.selectedEvent = selectedEvent;
          this.event = selectedEvent;
          this.location = this.event.location
       },
       dateFormat(date) {
        return dayjs(date).format('DD.MM.YYYY');
       },
    }
}
</script>

<style scoped>
    input[type=text] {
        width: 100%;
        display: inline-block;
        box-sizing: border-box;
        padding: 7px 7px 7px 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box;
        resize: vertical;
        background-color: #dfdfdf;
    }
    .container-dropdown {
        display: block;
        margin-top: 0;
        margin-left: 10%;
        margin-bottom: 10%;
        width: 200px;
    }
    
    select {
        width: 80%;
        border-radius: 5px;
    }
</style>