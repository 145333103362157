import Vue from 'vue'
import App from './App.vue'
import router from './router'

import './assets/main.css'

import LandPage from './components/LandPage.vue';
import EventsPage from './components/EventsPage.vue';
import RegistrationPage from './components/RegistrationPage.vue';
import InfoPage from './components/InfoPage.vue';
import EventKategorie from './components/EventKategorie.vue';
import CarouselPage from './components/CarouselPage.vue';
import CarouselSlide from './components/CarouselSlide.vue';
import ImpressumPage from './components/ImpressumPage.vue';
import AdminPage from './components/AdminPage.vue'

import axios from "axios";
import VueAxios from "vue-axios";
import Notifications from "vue-notification";
import store from './store/store.js';
import { Buffer } from 'buffer';

import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isToday from 'dayjs/plugin/isToday';
import duration from 'dayjs/plugin/duration';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';
import de from 'dayjs/locale/de';

dayjs.extend(utc)
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(isToday);
dayjs.extend(customParseFormat);
dayjs.extend(duration);
dayjs.extend(isSameOrAfter);
dayjs.extend(weekOfYear);
dayjs.extend(isSameOrBefore);
dayjs.extend(updateLocale);


dayjs.locale(de);


Vue.component("land-page", LandPage);
Vue.component("events-page", EventsPage);
Vue.component("registration-page", RegistrationPage);
Vue.component("info-page", InfoPage);
Vue.component("event-kategorie", EventKategorie);
Vue.component("carousel-page", CarouselPage);
Vue.component("carousel-slide", CarouselSlide);
Vue.component("impressum-page",ImpressumPage);
Vue.component("admin-page", AdminPage);
Vue.use(VueAxios, axios);
Vue.use(Notifications);
Vue.use(router);
Vue.use(store);
Vue.use(Buffer);
Vue.use(dayjs);

window.Buffer = window.Buffer || Buffer;


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')