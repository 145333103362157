<template>
    <div id="carrouselPage" class="carrousel">
        <slot></slot>
        <img src="../images/arrow_left.png" alt="arrow_left" class="prev" @click="prev">
        <img src="../images/arrow_right.png" alt="arrow_right" class="next" @click="next">
    </div>
</template>

<script>
export default {
    
    data() {
        return {

        }
    },
    methods: {
        next() {
            this.$emit('next');
        },
        prev() {
            this.$emit('prev');
        }
    }
}
</script>

<style scoped>
    .carousel{
        position: relative;
        display: flex;
    }


    img {
        outline: none;
        cursor: pointer;
        position: absolute;
        width: -10%;
    }

    .next {
        right: 0;
        margin: 0;
        margin-top: 20%;
    }

    .prev {
        left: 0;
        margin: 0;
        margin-top: 20%;
    }

    @media screen and (max-width: 450px){
        .next , .prev {
            margin-top: 45%;
        }
    }

    @media screen and (min-width: 1500px) and (max-width: 1900px){
        .next , .prev {
            margin-top: 15%;
        }
    }

    @media screen and (min-width: 1900px){
        .next , .prev {
            margin-top: 15%;
        }
    }

</style>