<template>
    <div id="info-container" class="row-gap-3">
        <div class="title">
            <div>WER <mark style="font-weight: bold;">WIR</mark> SIND & WAS WIR <mark style="font-weight: bold;">MACHEN</mark> </div>
        </div>
        <div class="image-parent-div">
          <div class="s2g_workers_image">
              <img src="../images/s2G_workers.png" alt="s2GWorkers">
          </div>
        </div>
        <div class="description">
            {{ description }}
        </div>
        <div class="footer row-gap-4">
          <div class="button-container">
            <button @click="openS2GPage">
              <a target="_blank">Zur Website</a>
            </button>
          </div>
          <div class="impressum-div">
            <router-link to="/impressum" class="impressum">Impressum & Datenschutz</router-link>
          </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            description: 'We make IT happen!\n' +
                'Als kleines Linzer Softwareunternehmen entwickeln wir personalisierte Software Lösungen für die unterschiedlichsten Unternehmen.\n' +
                'Mit Leidenschaft, langjähriger Erfahrung und umfangreichen Know-how lassen wir bestehende IT Systeme in neuem Licht erstrahlen oder gestalten eine Software, zugeschnitten auf Ihre Wünsche und Anforderungen. \n' +
                'Unser Ziel ist es, effiziente und kreative Lösungen für geschäftliche Herausforderungen zu bieten.'
        }
    },
    methods: {
      openS2GPage() {
        window.open(
            'https://s2g.at',
            '_blank'
        );
      }
    }
}
</script>

<style lang="scss" scoped>

    #info-container {
        display: flex;
        flex-direction: column;
        min-height: 90vh; // padding bottom of parent (class fullpage) = 5%, margin-top: 5% -> 90% remaining
        //max-height: 90vh;
        padding-top: 5vh;
        margin-left: auto;
        margin-right: auto;
        width: 80%;
        text-align: center;
    }

    mark {
        background: none;
        color: var(--s2g-primary-color);
    }

    .title {
      width: 100%;
      font-size: var(--font-size-title-xl);
      color: black;
      margin-left: 0;
      display: flex;
      flex-direction: column;
      white-space: pre-wrap;

      div {
        font-weight: bold;
        margin-bottom: 0;
        margin-left: 0;

        //@media (prefers-color-scheme: dark) {
        //  color: white;
        //}
      }
    }

    .description {
        white-space: pre-wrap;
        word-break: break-word;
        margin: auto;
    }

    .impressum-datenschutz {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
    }

    .s2g_workers_image {
      overflow: hidden;
      //flex: 1;
      max-height: 50vh;
      //height: 30%;

      img {
        max-height: 50vh;
        width: 100%;

        object-fit: contain;
      }
    }

    .image-parent-div {
      //flex: 1;
      min-height: 10px;
    }

    //.s2g_workers_image {
    //  align-self: center;
    //  //display: flex;
    //  background-color: blue;
    //  min-height: 1px;
    //  min-width: 1px;
    //  max-height: 100px;
    //  flex-basis: auto;
    //  flex-grow: 0;
    //  flex-shrink: 0;
    //
    //  img {
    //    //max-height: 33vh;
    //    height: 70%;
    //    width: 100%;
    //    //flex: 1;
    //    overflow: hidden;
    //    object-fit: contain;
    //  }
    //}

    .impressum-div {
      //align-self: end;

      .impressum {
        text-align: end;
        color: var(--color-text);
        font-weight: bold;

        //@media (prefers-color-scheme: dark) {
        //  color: white;
        //}
      }
    }

    .footer {
        width: 100%;
        display: flex;
        flex-direction: column;
        //justify-content: space-between;
        //align-items: end;
    }

    .button-container {
      justify-content: center;
      flex: 1;
    }

    .button {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 5%;
    }

</style>