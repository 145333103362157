<template>
    <div id="eventsPage" class="grid-container">
        <div id="eventsCarousel" class="carousel">
            <carousel-page
                @next="next()"
                @prev="prev()">
                <carousel-slide 
                    v-for="(slide, index) in events"
                    :key="slide" 
                    :index="index"
                    :visibleSlide = "visibleSlide"
                    class="carousel-slider">
                    <div class="image-label-container">
                        <img class="image-first" :src="images[index - 1]" :alt="titles[index-1]" @click="selectedEvent(events[index - 1])" />
                        <label for="label-first">{{ titles[index-1] }}</label>
                    </div>
                    <div class="image-label-container">
                        <img class="image-second" :src="images[index]" :alt="titles[index]" @click="selectedEvent(events[index])" />
                        <label for="label-second">{{ titles[index] }}</label>
                    </div>
                </carousel-slide>
            </carousel-page>
        </div>
        <!--   
        <span class="">
            <input id="profilePictureUpload" type="file" accept="image/*"  @change="uploadFile($event)">
        </span>
        -->    
        <div class="event-kategorie">
            <EventKategorie :selectedEvent="eventSelected" :isEventPage="isEventPage" @registrationEvent="registrationEvent"/>
        </div>
        <div class="event-image" >
            <img :src="eventSelected.uid ? eventSelected.image : event.image" :alt="eventSelected.name">
        </div>
    </div>
</template>

<script>
import EventKategorie from './EventKategorie.vue';
import CarouselPage from '@/components/CarouselPage';
import CarouselSlide from '@/components/CarouselSlide';

export default {
    props:[],
    components: {
        CarouselPage: CarouselPage,
        CarouselSlide: CarouselSlide,
        EventKategorie
    },  
    data(){
        return {
            visibleSlide: 1,
            buttonActive: true,
            eventSelected: {},
            isEventPage: true,
            image: {}
        }
    },
    computed: {
        event() {
            return this.$store.state.event;
        },
        events() {
            return this.$store.state.events;
        }, 
        images() {
            const images = [];
            this.events.forEach(e => {
                images.push(e.image)
            })
            return images;
        },
        titles() {
            const titles = [];
            this.events.forEach(e => {
                titles.push(e.name)
            })
            return titles;
        },
    },
    watch:{
        eventSelected: {
            handler(newValue, oldValue){
              console.log('newValue: ', newValue);
                if(!newValue.uid || newValue.uid != oldValue.uid){
                    this.selectedEvent(newValue);
                }
            },
            deep: true,
          // immediate: true
        },
    },
    methods: {
        next() {
            if(this.visibleSlide >= this.images.length - 1) {
                this.visibleSlide = 1;
            } else {
                this.visibleSlide++;
            }
        },

        prev() {
            if(this.visibleSlide <= 1) {
                this.visibleSlide = this.images.length - 1;
            } else {
                this.visibleSlide--;
            }
        },

        selectedEvent(event) {
            this.eventSelected = event;
        },

        registrationEvent(e) {
            this.$emit('registrationEvent', e)
        },

        uploadImage(event){
            let file = event.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(file)
            reader.onload = e => {
                if(confirm('Do you want to upload ' + file.name + '?')){
                    console.log(e.target.result)
                    this.$store.dispatch('UPLOAD_IMAGE', { image: e.target.result });
                }
            }
        },

        uploadImageFile(event){
            let file = event.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(file)
            reader.onload = e => {
                if(confirm('Do you want to upload ' + file.name + '?')){
                    console.log(e.target.result)
                    this.$store.dispatch('UPLOAD_IMAGE_FILE', e.target.result);
                }
            }
        },

        /*used for General File being saved in DB*/
        uploadFile(event){
            let file = event.target.files[0];
            console.log(file)
            const reader = new FileReader();
            reader.readAsDataURL(file)
            reader.onload = () => {
                if(confirm('Do you want to upload ' + file.name + '?')){
                    const formData = new FormData();
                    formData.append('files', file);
                    this.$store.dispatch('UPLOAD_FILE', { files : formData });
                }
            }
        },

        /*used for image directly save in DB*/
        decodeImage(image) {
            console.log(image)
            if(image === undefined || image === null || image === '') {
                return require('../images/bird_chatbox.png')
            }
                return Buffer.from(image, 'base64').toString('utf8');
        }
    }
}
</script>

<style scoped>
    .grid-container {
        display: grid;
        align-self: center;
        grid-template-columns: 12.5% 37.5% 37.5%  12.5%;
        grid-template-rows: 45% 55%;
        gap: 10px 0px;
        height: 100%;
    }

    .carousel {
        grid-row-start: 1;
        grid-column-start: 2;
        grid-column-end: 4;
    }

    .event-kategorie {
        box-sizing: content-box;
        grid-row-start: 2;
        grid-column-start: 2;
        grid-column-end: 3;
        margin-top: 0;
        margin-left: 5%;
        width: 100%;
        overflow: hidden;
    }

    .event-image {
        grid-row-start: 2;
        grid-column-start: 3;
        grid-column-end: 4;
    }

    .event-image img {
        width: 100%;
        height: 90%;
        margin-top: 10px;
    }

    .carousel-slider {
        display: flex;
        flex-direction: row;
        position:absolute;
        margin-top: 40px;
        margin-left: 8%;
    }

    .image-first, .image-second {
        width: 500px;
        height: 350px;
    }

    .image-label-container {
        display: flex;
        flex-direction: column;
    }

    label {
        margin-left: 15px;
    }

    @media only screen and (min-width: 1280px){
        
        .grid-container {
            gap: 0px;
        }
        
        .carousel-slider {
            margin-top: 0;
            margin-left: 7%;
        }

        .image-first, .image-second {
            width: 300px;
            height: 200px;
            margin: 75px 15px 0px;
        }

        .event-image img {
            height: 320px;
        }
    }

    @media only screen and (min-width: 1365px) and (max-width: 1535px) {
        
        .image-first, .image-second {
            width: 300px;
            height: 230px;
            margin: 75px 15px 0px;
        }

        .carousel-slider {
            margin-left: 10%;
        }
    }

    @media only screen and (min-width: 1536px) and (max-width: 1600px) {

        .image-first, .image-second {
            width: 350px;
            height: 250px;
            margin: 60px 15px 0px;
        }

        .carousel-slider {
            margin-left: 9%;
        }

        .event-image img {
            width: 100%;
            height: 400px;
            margin-top: 10px;
        }
    }

    @media only screen and (min-width: 1600px) and (max-width: 1700px) {

        .image-first, .image-second {
            width: 400px;
            height: 300px;
            margin: 30px 10px 0px;
        }

        .carousel-slider {
            margin-left: 8%;
        }
    }

    @media only screen and (min-width: 1700px) and (max-width: 1800px) {

        .image-first, .image-second {
            width: 400px;
            height: 300px;
            margin: 30px 10px 0px;
        }

        .carousel-slider {
            margin-left: 10%;
        }
    }

    @media only screen and (min-width: 1800px) {

        .image-first, .image-second {
            width: 450px;
            height: 300px;
            margin: 80px 0px 0px 20px;
        }

        .carousel-slider {
            margin-left: 7%;
        }

        .event-image img {
            height: 80%;
        }

        label {
            font-size: large;
            margin-left: 20px;
        }

        @media only screen and (max-height: 1000px) {
            .carousel-slider {
                margin-left: 10%;
            }
        }

    }

    @media only screen and (max-width: 500px) {
        .grid-container {
            grid-template-columns: 100%;
            grid-template-rows: 45% auto;
            gap: 0px;
            height: 100%;
        }

        .carousel {
            grid-row-start: 1;
            grid-column-start: 1;
            grid-column-end: 1;
        }

        .event-kategorie {
            grid-row-start: 2;
            grid-column-start: 1;
            grid-column-end: 1;
            margin-top: 5%;
            margin-left: 0%;
        }

        .image-first {
            max-width: 300px;
            height: 250px;
            margin: 0;
        }

        .image-second, .event-image, label[for="label-second"] {
            display: none;
        }

        label {
            margin-left: 0;
        }

        .carousel-slider {
            position:absolute;
            margin-top: 20%;
            margin-left: 9%;
        }
    }

    @media only screen and (min-width: 390px) and (max-width: 500px) {

        .carousel-slider {
            margin-left: 12%;
        }
    }

    @media only screen and (min-width: 414px) and (max-width: 500px) {

        .carousel-slider {
            margin-left: 14%;
        }

        .event-kategorie {
            margin-top: 0%;
        }
    }

</style>